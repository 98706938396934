import React, { Component } from 'react';
import ResourcesCard from './ResourcesCard';
import case1 from '../../images/casestudyresourcesaltV.1.jpg'
import case2 from '../../images/casestudyresourcesaxaV.1.jpg'
import case3 from '../../images/casestudyresourcescromptonV.1.jpg'
import case4 from '../../images/casestudyresourcesiiflV.1.jpg'
import case5 from '../../images/casestudyresourcesmmtV.1.jpg'
import case6 from '../../images/casestudyresourcespetroV.1.jpg'
import case7 from '../../images/casestudyresourcespumaV.1.jpg'
import case8 from '../../images/casestudyresourcesdharafV.1.jpg'
import case9 from '../../images/casestudyresourcesV.1.jpg'
import case10 from '../../images/SoniaMediacomCS.jpg'
import case11 from '../../images/sodexo-cs.jpg'
import report1 from '../../images/reportnewV.1.jpg'
import report2 from '../../images/reportinfeedoV.1.jpg'
import report3 from '../../images/navigatingcovidreportV.1.jpg'
import report4 from '../../images/report10questionsV.1.jpg'
import report5 from '../../images/reporthacksV.1.jpg'
import report6 from '../../images/reportengagementV.1.jpg'
import report7 from '../../images/reportimapactengaementV.1.jpg'
import report8 from '../../images/report8.jpg'
import report9 from '../../images/balancingworkcovidinfeedoV.1.jpg'
import report10 from '../../images/websiteandbrandingcrisismanagement.jpg'
import report11 from '../../images/websiteandbrandingwhitepape.jpg'


export default class Resourcefeed extends Component {

    state = {
        whitepaper: [
            {
                image: report6,
                title: 'Report',
                text: 'What motivates employees at work in 2020',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            },
            {
                image: report6,
                title: 'Report',
                text: 'The BFSI Employee Engagement Index 2020',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            },
            {
                image: report6,
                title: 'Report',
                text: 'Navigating COVID-19: 3 Keys for Organisations to Come Out on Top',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            }
           
    ],
        caseStudies: [
        {
            image: case11,
            title: 'Sodexo',
            text: 'Sodexo Retains 100% of At-Risk Employees with AI',
            link: 'https://f.hubspotusercontent00.net/hubfs/5722254/Case%20Study_sodexoFinal.pdf',
            id: 'sodexo_case_study'
        },
        {
            image: case10,
            title: 'Mediacom',
            text: 'MediaCom connects with 2000+ employees in real-time & achieves 33X ROI in employee engagement with Amber.',
            link: 'https://f.hubspotusercontent00.net/hubfs/5722254/Case%20Study_mediacomupdatedV.1.pdf',
            id: 'mediacom_case_study'
        },
        {
            image: case1,
            title: 'Altimetrik',
            text: 'Altimetrik connects with 1500 employees in real-time across 16 locations & saves 64% at-risk employees.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Altimetrik-1.pdf',
            id: 'altimetrik_case_study'
        },
        
        {
            image: case2,
            title: 'AXA',
            text: 'AXA Affin complements bi-annual pulse surveys with AI & retains 78% at-risk employees.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/AXA_%20Malaysia_Amber_CaseStudy-1.pdf',
            id: 'axa_case_study'
        },
        {
            image: case3,
            title: 'Crompton',
            text: 'Crompton connects with employees across 19 branches & redefines its onboarding process as an HR KRA.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Crompton.pdf',
            id: 'crompton_case_study'
        },
        {
            image: case4,
            title: 'IIFL',
            text: 'India Infoline  seamlessly connects with 19000+ employees across 1000+ locations & multiple business units.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_IIFL.pdf',
            id: 'iifl_case_study'
        },
        {
            image: case5,
            title: 'Go-MMT',
            text: 'MMT (MakeMyTrip, Goibibo, Redbus) connects with 3000+ employees in real-time after amega merger.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_MMT%20(1).pdf',
            id: 'go_mmt_case_study'
        },
        {
            image: case6,
            title: 'Petrotec',
            text: 'Petrotec increases trust & transparency in the organization by ensuring each employee is heard. ',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Petrotec.pdf',
            id: 'petrotec_case_study'
        },
        {
            image: case7,
            title: 'Puma',
            text: 'Puma captures & acts on employee feedback, sees positive impact across 91% factors affecting employee experience.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Puma.pdf',
            id: 'puma_case_study'
        },
        {
            image: case8,
            title: 'SharafDG',
            text: 'Sharaf DG connects with 1500+ employees across UAE, Oman,Bahrain & Egypt, retains 88% employees predicted at-risk by Amber.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Sharaf%20DG.pdf',
            id: 'sharaf_dg_case_study'
        },
        {
            image: case9,
            title: 'Tata Cliq',
            text: 'Tata CLiQ adds AI to their engagement determination framework to prioritize & address each concern highlighted by employees.',
            link: 'https://cdn2.hubspot.net/hubfs/5722254/Case%20Study_Tata%20CliQ.pdf',
            id: 'tata_cliq_case_study'
        }
        
    ],

        reports: [
            {
                image: report11,
                text: "A deep dive into the engine behind Amber's EX Framework",
                link: 'https://f.hubspotusercontent00.net/hubfs/5722254/infeedowhitepaper2020updatedlinksV.1.pdf',
                id: 'infeedos_ex_framework_report'
                
            },
            {
                image: report10,
                text: '20+ Ultimate Crisis Management Takeaways by HR for HR',
                link: 'https://f.hubspotusercontent00.net/hubfs/5722254/vgdinfeedoreportV.1.pdf',
                id: 'ultimate_crisis_managment_report'
                
            },
            {
                image: report9,
                text: '26-Point checklist for your safe return to the workplace',
                link: 'hhttps://f.hubspotusercontent00.net/hubfs/5722254/Balancing%20remote%20work%20%26%20the%20workspace.pdf',
                id: '26_point_checklist_report'
                
            },
            {
                image: report8,
                text: 'World of Work before and after COVID-19',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/World_of_work_after_covid.pdf',
                id: 'world_of_work_report'
                
            },
            {
                image: report1,
                text: 'What motivates employees at work in 2020',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/What%20Motivates%20Employees%20in%202020.pdf',
                id: 'what_motivates_employees_report'
                
            },
            {
                image: report2,
                text: 'The BFSI Employee Engagement Index 2020',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/BFSI%20Employee%20Engagement%20Report%202020.pdf',
                id: 'bfsi_employee_engagement_report'
                
            },
            {
                image: report3,
                text: 'Navigating COVID-19: 3 Keys for Organisations to Come Out on Top',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/navigatingkitupdatedV.1.pdf',
                id: 'navigating_covid_19_report'
                
            },
            {
                image: report4,
                text: '10 EX Questions to ask your employees before they leave you',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/SurveyQuestionnaireV.1-3.pdf',
                id: '10_ex_questions_report'
                
            },
            {
                image: report5,
                text: 'Employee Engagement Hacks by 30+ People Leaders across Global Organizations',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/HR%20Engagement%20Hacks_ebook_v1.0.pdf',
                id: 'employee_engagement_hacks_report'
                
            },
            {
                image: report6,
                text: 'The State of Employee Engagement in 2019',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/HR.com%20Research%20Report.pdf',
                id: 'state_of_employee_report'
                
            },
            {
                image: report7,
                text: '3 Steps to Measure & Maximize Impact of Employee Engagement in 2020',
                link: 'https://cdn2.hubspot.net/hubfs/5722254/HRcom.pdf',
                id: '3_steps_measure_report'
                
            }
        ],

        ebooks: [
            {
                image: report1,
                title: 'Report',
                text: 'What motivates employees at work in 2020',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            },
            {
                image: report6,
                title: 'Report',
                text: 'The BFSI Employee Engagement Index 2020',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            },
            {
                image: report6,
                title: 'Report',
                text: 'Navigating COVID-19: 3 Keys for Organisations to Come Out on Top',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                
            }
        ],
        one: [],
        two: [],
        three: []
    }

    componentDidMount() {
        this.createVerticalArrays();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.tab !== this.props.tab) {
            this.createVerticalArrays();
        }
    }

    createVerticalArrays = () => {
       
        const one = []; 
        const two = [];
        const three = [];
        const posts = this.getPosts();
        posts.forEach((article, index) => {
            if(index % 3 === 0){
                one.push(article);
            }
            if(index % 3 === 1){
                two.push(article);
            }
            if(index % 3 === 2){
                three.push(article);
            }
        });
        this.setState({one, two, three});
    }

    getPosts= ()=>{
        const { caseStudies, reports, ebooks, whitepaper } = this.state;
        if(this.props.tab==='caseStudies')
        {
            return caseStudies;
        }
        else if(this.props.tab==='reports')
        {
            return reports;
        }
        else if(this.props.tab==='ebooks')
        {
            return ebooks;
        }
        else if(this.props.tab==='whitepaper')
        {
            return whitepaper;
        }
        
        
    }

    render() {
        const { one, two, three, whitepaper, ebooks, caseStudies, reports } = this.state;
        const { tab } = this.props;
        return (
            <div className="pd-15-mobile">
                <div className="tile is-ancestor">
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {one.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {two.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard  card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {three.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard  card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-tablet">
                        {tab === 'caseStudies'? caseStudies.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard card_data={article}/>
                                </div>
                            </div>
                        )): tab === 'reports'? reports.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard  card_data={article}/>
                                </div>
                            </div>
                        )): tab === 'ebooks'? ebooks.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard card_data={article}/>
                                </div>
                            </div>
                        )): whitepaper.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <ResourcesCard  card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
