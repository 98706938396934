import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResourcesHead from "../components/resources/ResourcesHead";
import NewsRoomPR from "../components/newsroom/NewsfeedPR";

const Resources = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Resources" description="Amber is an AI chatbot that talks to your employees to helps you measure employee experience in your organization. As Amber travels the world, catch all the action here. Be a part of our ever-growing community."/>
    <ResourcesHead/>
    <NewsRoomPR/>
  </Layout>
)

export default Resources;