import React, { Component } from 'react';
import PropTypes from "prop-types";
import PopupModal from 'react-modal';
import Amber_img from '../../images/AmberPopup.png';
import Amber_sad from '../../images/amber-sad.png';
import axios from '../../helper/Axios';
 

export default class ResourceModal extends Component {
    state = {
        email: '',
        phone: undefined,
        demo_intent: ''
    }

    getCookie=(name)=>
  {
    let re = new RegExp(name + "=([^;]+)");
    let value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;}
    componentDidUpdate(prevProps){
        const { trigger_tour } = this.props;
        if(prevProps.trigger_tour !== trigger_tour){
            this.setState({is_active: true});
        }
    }
    
    submitData =  async (e) => {
        e.preventDefault();
        const {  email, phone, demo_intent } = this.state;
        const {resource_link} = this.props;
        let payload={};
        try{
            const path = window.location.pathname;
            const page_name = path.split("/").pop();
            const page_uri = window.location.href;
            const hutk = this.getCookie('hubspotutk');
            payload = { email, phone, demo_intent,
                organic_demo: true, page_uri, hutk, page_name
            }
            await axios.post('/hubspot/resource_form', payload);
            let thankingMsg =  document.getElementById('thankingMsgDiv'); 
            thankingMsg.classList.remove("is-hidden");
            let popupForm =  document.getElementById('popup-modal-form'); 
            popupForm.classList.add("is-hidden");
            window.open(resource_link, '_blank')
        }
        catch (error) {
            if(error && error.response && error.response.status === 409){
                let thankingMsg =  document.getElementById('thankingMsgDiv'); 
                thankingMsg.classList.remove("is-hidden");
                let popupForm =  document.getElementById('popup-modal-form'); 
                popupForm.classList.add("is-hidden");
            }
            else {
                let thankingMsg =  document.getElementById('errorMsgDiv'); 
                thankingMsg.classList.remove("is-hidden");
                let popupForm =  document.getElementById('popup-modal-form'); 
                popupForm.classList.add("is-hidden");
            }
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const {is_active, resource_img,resource_title, onClose, resource_link, resource_id} = this.props;
        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)'
            }
          };
            
        return (
            <PopupModal
            ariaHideApp={false}
            isOpen={is_active}
            style={customStyles}
            onRequestClose={onClose}
            contentLabel="Resource Modal">
                
                <div className="popup-modal-desktop">
                    <div className="popup-modal-closeDiv" onClick={onClose}>
                    <svg  className="popup-modal-close" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
                    </div>
                    <div className="popup-modal-thankingDiv is-hidden " id ="thankingMsgDiv">
                    <img  className ="popup-modal-amberImg"src={Amber_img} alt="Placeholder"></img>
                    <h2 className="popup-modal-thankingHeader is-success-green">Thank you for signing up!</h2>
                    <h3 className="popup-modal-thankingText">We hope to stay connected. You will now be redirected to the case study</h3>
                    </div>
                    <div className="popup-modal-thankingDiv is-hidden " id ="errorMsgDiv">
                    <img  className ="popup-modal-amberImg"src={Amber_sad} alt="Placeholder"></img>
                    <h2 className="popup-modal-thankingHeader is-error-red">Oops!</h2>
                    <h3 className="popup-modal-thankingText">Sorry, something went wrong at our end. Please try again later</h3>
                    </div>
                <div className="popup-modal-content" id="popup-modal-form">
                <div className="popup-modal-text">
                    <h2 className="popup-modal-heading">{resource_title}</h2>
                    <div className="popup-modal-img is-hidden-tablet">
                    <img src={resource_img} alt="Placeholder"></img>
                    </div>
                    <form id="form-div" onSubmit={this.submitData}  name="sign up for beta form">
                        <div className="popup-modal-form">
                        <input type="email"  name="email" className="popup-modal-email-field" placeholder="Business Email" required onChange={this.onChange}></input>
                        <input type="text" pattern="[1-9]{1}[0-9]{9}" name ="phone"className="popup-modal-phone-field" placeholder="Phone Number" required  onChange={this.onChange}></input>
                        <input type="hidden" name ="form_submitted_on"   value={resource_link} ></input>
                         <h2 className="popup-modal-knowMoreText">Would you like to measure, track & act on real-time employee sentiment?</h2>
                        <p className="popup-modal-radioField">
                        <input type="radio" id="popup-modal-yesToMore" name="demo_intent" value="Yes"  onChange={this.onChange}></input>
                        <label className="popup-modal-knowMoreText" htmlFor="popup-modal-yesToMore">Yes, definitely!</label>
                        <input type="radio" id="popup-modal-noToMore" name="demo_intent" value="No" required  onChange={this.onChange}></input>
                        <label className="popup-modal-knowMoreText" htmlFor="popup-modal-noToMore">No, thank you.</label>
                        </p>
                         <input id ={`${resource_id}_submit`} type="submit" className= "popup-modal-submit" value="Get Free Copy"></input>
                        </div>
                    </form>
                </div>
                <div className="popup-modal-img is-hidden-mobile">
                    <img src={resource_img} alt="Placeholder"></img>
                </div>
            </div>
            </div>
          </PopupModal>
        )
    }
}

ResourceModal.propTypes = {
    is_active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    resource_title : PropTypes.string.isRequired,
    resource_link: PropTypes.string.isRequired   
}

